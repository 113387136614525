<template>
  <div class="flex xl:mx-auto p-8 z-20">
    <div class="xl:container xl:w-8/11 mx-auto">
      <Steps class="text-white border-4 text-xs border-white rounded-md z-0" :pageIndex="index-1" :items="items"/>
      <CompanyData v-if="index === 1" @cancel="cancel" @next="next" :clientType="clientType" :data="form.empresa"/>
      <RepresentativeData v-if="index === 2" @back="back" @next="next" :data="form.representantes"/>
      <TechnicalData v-if="index === 3" @back="back" @next="next" :data="form.tecnicos"/>
      <ValidateData v-if="index === 4" @back="back" @next="next" :form="form"/>
      <transition name="fade">
          <div class="toast flex" v-if="tecnoaccount">Seus dados foram sincronizados com o Tecnoaccount! <div @click="tecnoaccount = !tecnoaccount" class="close-button">x</div></div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Steps } from '@design-system/ui'
import CompanyData from './wizard/CompanyData.vue'
import RepresentativeData from './wizard/RepresentativeData.vue'
import TechnicalData from './wizard/TechnicalData.vue'
// import AditionalData from './wizard/AditionalData.vue'
// import FinancialData from './wizard/FinancialData.vue'
import ValidateData from './wizard/ValidateData.vue'
// import ContractData from './wizard/ContractData.vue'
// import TecnoaccountData from './wizard/TecnoaccountData.vue'
// import PlugPixData from './wizard/PlugPixData.vue'
import { faUsers, faGlobeAmericas, faUserShield, faCheckDouble, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'Create',
  props: ['data', 'protocolValue', 'clientType', 'CNPJ'],
  components: {
    CompanyData,
    RepresentativeData,
    TechnicalData,
    ValidateData,
    // AditionalData,
    Steps
    // FinancialData,
    // ContractData,
    // TecnoaccountData,
    // PlugPixData,
  },
  watch: {
    data: function (data) {
      this.index = 4
      this.form = data
    }
  },
  data () {
    return {
      index: 1,
      form: { empresa: {}, tecnicos: [], representantes: [] },
      questionCircle: faQuestionCircle,
      protocol: null,
      loading: false,
      retrying: false,
      tecnoaccount: false,
      items: [
        {
          label: 'Empresa',
          icon: faGlobeAmericas,
          index: 0
        },
        {
          label: 'Representantes Legais',
          icon: faUsers,
          index: 1
        },
        {
          label: 'Usuários',
          icon: faUserShield,
          index: 2
        },
        {
          label: 'Validação',
          icon: faCheckDouble,
          index: 3
        }
      ]
    }
  },
  methods: {
    async next (data) {
      switch (this.index) {
        case 1:
          this.form.empresa = data
          this.index++
          break
        case 2:
          this.form.representantes = data
          this.index++
          break
        case 3:
          this.form.tecnicos = data
          this.index++
          break
        case 4:
          this.$router.push('/')
          break
      }
    },

    back (data) {
      switch (this.index) {
        case 1:
          this.form.empresa = data
          break
        case 2:
          this.form.representantes = data
          break
        case 3:
          this.form.tecnicos = data
          break
      }

      this.index--
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
