
import { defineComponent } from 'vue'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { PrimaryButton } from '@design-system/ui'

export default defineComponent({
  name: 'NotFound',
  components: {
    PrimaryButton
  },
  data () {
    return {
      buttonIcon: faChevronCircleLeft,
      hasToken: false
    }
  },
  methods: {
    handleBack () {
      if (this.hasToken) {
        this.$router.go(-2)
      } else {
        this.$router.push('/')
      }
    }
  },
  created () {
    const token = localStorage.getItem('token')
    if (token) {
      this.hasToken = true
    }
  },
  mounted () {
    const item = document.getElementById('button')
    // eslint-disable-next-line no-unused-expressions
    item?.classList.toggle('custom-button')
  }
})
