import { createStore } from 'vuex'
import { faChartBar, faIdBadge, faComment, faFile, faUsers, faMoneyBill, faKey, faDownload } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface IUser {
    name: string;
    email: string;
    cnpj: string;
    system: string;
    type: string;
    active: boolean;
}

interface ISidebarMenu {
  name: string;
  route: string;
  disabled?: boolean;
  hidden?: boolean;
  textColor?: string;
  icon?: IconDefinition;
  iconCustomClass?: string;
}

interface IState {
  token: string;
  user: IUser;
  softwareHouse: any;
  sidebarMenu: ISidebarMenu[];
  sidebarActive: number;
  isSidebarCollapsed: boolean;
}

export default createStore<IState>({
  state: {
    token: '',
    softwareHouse: {},
    user: {
      name: '',
      email: '',
      cnpj: '',
      system: '',
      type: '',
      active: false
    },
    sidebarMenu: [
      {
        name: 'Painel',
        icon: faChartBar,
        iconCustomClass: 'text-white text-center',
        route: '/painel',
        hidden: false
      },
      {
        name: 'Download',
        icon: faDownload,
        iconCustomClass: 'text-white text-center',
        route: '/download'
      },
      {
        name: 'Financeiro',
        icon: faMoneyBill,
        iconCustomClass: 'text-white text-center',
        route: '/financeiro'
      },
      {
        name: 'Licenças',
        icon: faKey,
        iconCustomClass: 'text-white text-center',
        route: '/licencas'
      },
      {
        name: 'Contratos',
        icon: faFile,
        iconCustomClass: 'text-white text-center',
        route: '/contratos',
        hidden: false
      },
      {
        name: 'Contratos',
        icon: faFile,
        iconCustomClass: 'text-white text-center',
        route: '/contratos',
        hidden: false
      },
      {
        name: 'Usuário',
        icon: faIdBadge,
        iconCustomClass: 'text-white text-center',
        route: '/usuario'
      },
      {
        name: 'Solicitar Homologação',
        icon: faUsers,
        iconCustomClass: 'text-white text-center',
        route: '/homologacao',
        hidden: true
      },
      {
        name: 'Mensagens',
        icon: faComment,
        iconCustomClass: 'text-white text-center',
        route: '/mensagens',
        hidden: true
      }
    ],
    sidebarActive: 0,
    isSidebarCollapsed: false
  },
  mutations: {
    changeActiveMenu (state, menuIndex: number) {
      state.sidebarActive = menuIndex
    },
    hideUsersMenu (state, hidden: boolean) {
      state.sidebarMenu[4].hidden = hidden
    },
    collapseSidebar (state, isCollapsed: boolean) {
      state.isSidebarCollapsed = isCollapsed
    },
    populateToken (state, token) {
      state.token = token
    },
    populateUser (state, user) {
      state.user = user
    },
    populateSoftwareHouse (state, softwareHouse) {
      state.softwareHouse = softwareHouse
    },
    resetData (state) {
      state.token = ''
      state.user = {
        name: '',
        email: '',
        cnpj: '',
        system: '',
        type: '',
        active: false
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
