<style>
.counter {
    height: min-content !important;
    font-size: 13px;
    color: white;
    width: min-content !important;
}

.protocol {
    color: var(--color-brand);
    border: solid 3px var(--color-brand);
    font-weight: bold;
    border-radius: 0.5rem;
    width: fit-content;
    padding: 5px;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: 0.45s;
}

.protocol:hover {
    color: var(--color-brand-mid);
    border: solid 3px var(--color-brand-mid);
}
</style>

<template>
    <Card orientation="flex-col" class="py-8">
        <h1 class="px-8 inline-block text-3xl col-span-12 mb-7 font-bold text-gray-900 text-left w-full tracking-tight">Validação dos Dados</h1>
        <div class="flex gap-5 mb-5 w-full" v-if="!loadingData">
            <div class="p-3 mx-auto rounded-lg shadow-md col-span-12 md:col-span-6 lg:col-span-6 gap-4">
                <div class="w-full flex justify-between gap-3">
                    <h1>Dados da Empresa</h1>
                </div>
                <h2>{{form.empresa.NomeFantasia}}</h2>
                <div class="items-center flex justify-between gap-5">
                    <div class="col-span-12 lg:col-span-8">CNPJ: {{ form.empresa.CNPJ }}</div>
                    <div :class="validate(form.empresa.CNPJ, 'CNPJ') ? 'valid col-span-12 lg:col-span-6' : 'invalid col-span-12 lg:col-span-6'">
                        <FontAwesomeIcon v-if="validate(form.empresa.CNPJ, 'CNPJ')" :icon="successIcon"/>
                        <FontAwesomeIcon v-else :icon="errorIcon"/>
                    </div>
                </div>
                <div class="items-center flex justify-between gap-5">
                    <div class="truncate">CNAE: {{ form.empresa.CNAE }}</div>
                </div>
                <Validator
                    type="email"
                    :formatedValue="form.empresa.Email"
                    :value="form.empresa.Email"
                    :validated="validated"
                    :validating="validating"
                    :loading="loadingValidation"
                    @getCode="getCode(form.empresa.Email, 'email', 'empresa')"
                    @onChange="onChange"/>
            </div>
        </div>
        <!-- Dados Mockados, remover na versão beta -->
        <div class="w-full flex pr-8 gap-5 mt-8">
            <div class="ml-auto">
                <Modal id="data">
                    <template v-slot:label>
                        <div class="p-3 rounded-lg bg-gray-200 hover:bg-gray-300 font-semibold">
                            Confirmar Dados
                        </div>
                    </template>
                    <template v-slot:modal>
                        <ConfirmFormData :data="form" :clientType="clientType"/>
                    </template>
                </Modal>
            </div>
            <Button @click="back" iconPosition="left" label="Voltar"/>
            <PrimaryButton @click="next" :disabled="validated" iconPosition="right" :loading="loading" label="Finalizar" class="next"/>
        </div>
        <Toast :label="warning" :hidden="error" @hide="error = !error"/>
        <ModalUI :isOpen="finished">
          <div class="px-8 py-5">
            <div class="title w-full text-center">Successo!</div>
            <p class="text-center w-full my-8">O seus dados foram enviados com sucesso, ainda estão em fase de confirmação. Em breve você receberá acesso ao portal do cliente.</p>
            <div class="flex w-full">
              <PrimaryButton @click="finish()" :btnClass="'next'" iconPosition="right" :isLoading="loading" label="Fechar" class="m-auto"/>
            </div>
          </div>
        </ModalUI>
    </Card>
</template>

<script>
import { Card, PrimaryButton, Button, Toast, Modal as ModalUI } from '@design-system/ui'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheckCircle, faChevronLeft, faChevronRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { cnpj, cpf, phone } from 'validacao-docs'
import Validator from '../Validator'
import utils from '@/utils'
import Modal from '../../Modal.vue'
import ConfirmFormData from '../ConfirmFormData.vue'
import api from '@/services/api'

export default {
  name: 'RepresentativeData',
  props: ['form', 'protocol', 'clientType'],
  components: {
    FontAwesomeIcon,
    Card,
    PrimaryButton,
    Button,
    Modal,
    Validator,
    ConfirmFormData,
    Toast,
    ModalUI
  },
  data () {
    return {
      loadingValidation: false,
      message: null,
      mockingEmailValid: false,
      mockingPhoneValid: false,
      loadingData: true,
      loading: false,
      mocked: true,
      successIcon: faCheckCircle,
      spinner: faCircleNotch,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      validateStatus: null,
      nextable: false,
      validated: true,
      validating: false,
      error: true,
      finished: false,
      warning: null
    }
  },
  async created () {
    this.loadingData = false
  },
  methods: {
    isTesting () {
      return process.env.VUE_APP_NODE_ENV === 'test'
    },

    async next () {
      this.loading = true
      await api.post('clients', {
        cnpj: this.form.empresa.CNPJ.replace(/\D/g, ''),
        email: this.form.empresa.Email,
        ticketExpirationDay: this.form.empresa.Vencimento,
        legalName: this.form.empresa.RazaoSocial,
        tradingName: this.form.empresa.NomeFantasia,
        stateRegistration: this.form.empresa.InscricaoEstadual,
        exemptFromTax: this.form.empresa.Isento,
        monthlyIncome: Number(this.form.empresa.FaturamentoMensal.replace(/\D/g, '')),
        cnae: this.form.empresa.CNAE.replace(/\D/g, ''),
        phone: this.form.empresa.Telefone.replace(/\D/g, ''),
        customersQuantity: Number(this.form.empresa.Clientes),
        openingDate: this.form.empresa.DataAbertura,
        activityType: this.form.empresa.TipoAtuacao.value,
        taxRegime: this.form.empresa.RegimeTributario.value,
        constitution: this.form.empresa.ConstituicaoEmpresa.value,
        responsibles: this.form.tecnicos.map((tecnico) => {
          return tecnico.types.map((type) => {
            return {
              cpf: tecnico.CPF.replace(/\D/g, ''),
              cellphone: tecnico.Celular.replace(/\D/g, ''),
              fullName: tecnico.NomeCompleto,
              email: tecnico.Email,
              responsibleType: type
            }
          })
        }).flat(),
        legalRepresentatives: this.form.representantes.map((representante) => {
          return {
            cpf: representante.CPF.replace(/\D/g, ''),
            rg: representante.RG,
            uf: representante.UFRG.sigla,
            telephone: representante.Telefone.replace(/\D/g, ''),
            cellphone: representante.Celular.replace(/\D/g, ''),
            fullName: representante.NomeCompleto,
            socialName: representante.NomeSocial,
            motherName: representante.NomeMae,
            email: representante.Email,
            birthDate: representante.DataNascimento,
            address: {
              zipCode: representante.CEP.replace(/\D/g, ''),
              complement: representante.Complemento,
              street: representante.Endereco,
              number: representante.Numero,
              neighborhood: representante.Bairro,
              city: representante.Cidade.nome,
              uf: representante.UF.sigla,
              country: 'Brasil',
              cityIbgeCode: representante.Cidade.id
            }
          }
        }),
        address: {
          zipCode: this.form.empresa.CEP.replace(/\D/g, ''),
          complement: this.form.empresa.Complemento,
          street: this.form.empresa.Endereco,
          number: this.form.empresa.Numero,
          neighborhood: this.form.empresa.Bairro,
          city: this.form.empresa.Cidade.nome,
          uf: this.form.empresa.UF.sigla,
          country: 'Brasil',
          cityIbgeCode: this.form.empresa.Cidade.id
        }
      }).then(() => {
        this.finished = true
      }).catch((error) => {
        this.warning = error.response.data.message
        this.error = false
      })
      this.false = true
    },

    finish () {
      window.location.href = 'https://clientes.tecnospeed.com.br/'
    },

    back () {
      this.$emit('back')
    },

    getCode (value, type, field) {
      api.post('clients/validate-code', {
        email: value
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*' // NOSONAR
        }
      })

      this.validating = true
    },

    send (index, value, type) {
      this.loadingValidation = true
      api.patch('clients/validate-code/' + index, {
        code: value
      }).then((response) => {
        if (response.data.valid) {
          this.validated = false
          this.validating = false
        } else {
          this.warning = 'O código informado é inválido'
          this.error = false
        }
      }).catch((response) => {
        this.$emit('registerLastRequest', response)
        this.$swal({
          title: 'Erro!',
          text: 'O código informado é inválido.',
          confirmButtonText: 'Ok'
        })
      })
      this.loadingValidation = false
    },

    onChange (index, value) {
      this.send(index, value, String(index).includes('@') ? 'email' : 'telefone')
    },

    validate (value, field) {
      switch (field) {
        case 'CNPJ':
          return cnpj.isValid(value)
        case 'CNAE':
          return value.substr(0, 4) + '-' + value.substr(4, 1) + '/' + value.substr(5, 2)
        case 'CPF':
          return cpf.isValid(value)
        case 'Celular':
          return phone.isValidMask(value)
      }
    },

    applyMask (index, value) {
      return utils.applyMask(index, value)
    },

    async copy (value) {
      // eslint-disable-next-line promise/param-names
      const delay = ms => new Promise(res => setTimeout(res, ms))
      // this.$copyText(value)

      this.message = 'Protocolo copiado!'
      await delay(3000)
      this.message = null
    }
  }
}
</script>
