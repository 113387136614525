
import { defineComponent } from 'vue'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { Button, PrimaryButton, Modal } from '@design-system/ui'
import api from '@/services/api'

export default defineComponent({
  name: 'NotFound',
  components: {
    Button,
    PrimaryButton,
    Modal
  },
  data () {
    return {
      buttonIcon: faChevronCircleLeft,
      hasToken: false
    }
  },
  methods: {
    handleBack () {
      api.patch('clients/validate-hash-mask', {
        hash: this.$route.params.hash
      })
    },
    cancel () {
      window.close()
    }
  },
  created () {
    const token = localStorage.getItem('token')
    if (token) {
      this.hasToken = true
    }
  },
  mounted () {
    const item = document.getElementById('button')
    // eslint-disable-next-line no-unused-expressions
    item?.classList.toggle('custom-button')
  }
})
