import axios from 'axios'

const getUrl = () => {
  // URL de produção
  if (document.domain === 'clientes2.tecnospeed.com.br') {
    return 'https://portal-cliente.tecnospeed.com.br/api/v1'
  }

  // URL de sandbox
  if (document.domain === 'sandbox-clientes.tecnospeed.com.br') {
    return 'https://portal-cliente.tecnospeed.com.br/sandbox/api/v1'
  }

  // URL local
  return 'http://localhost:3335/api/v1'
}

const api = axios.create({
  baseURL: getUrl()
})

export default api
