
import { defineComponent } from 'vue'
import ConfirmFormData from '@/components/Register/ConfirmFormData.vue'

export default defineComponent({
  name: 'Estabelecimentos',
  components: {
    ConfirmFormData
  }
})
