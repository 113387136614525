
import { defineComponent } from 'vue'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'Toast',
  components: {
    FontAwesomeIcon
  },
  props: {
    label: String,
    hidden: Boolean,
    success: Boolean
  },
  data () {
    return {
      close: faTimes
    }
  }
})
