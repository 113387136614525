
import { defineComponent } from 'vue'
import { Card } from '@design-system/ui'
import VueApexCharts from 'vue3-apexcharts'

export default defineComponent({
  name: 'Estabelecimentos',
  components: {
    Card,
    VueApexCharts
  },
  data () {
    return {
      search: null,
      options: {
        chart: {
          id: 'vuechart'
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50]
      }],
      pieSeries: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie'
        },
        dataLabels: {
          enabled: false
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  methods: {
  },
  beforeMount () {
    this.$store.commit('changeActiveMenu', 0)
  }
})
