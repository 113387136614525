export default class util {
  applyMask (name, value) {
    if (Array.isArray(name)) name = name[1]
    if (value) {
      value = value.replace(/[^a-z0-9]/gi, '')
      switch (name) {
        case 'CNPJ':
          return value.substr(0, 2) + '.' + value.substr(2, 3) + '.' + value.substr(5, 3) + '/' + value.substr(8, 4) + '-' + value.substr(12, 2)
        case 'CNAE':
          return value.substr(0, 4) + '-' + value.substr(4, 1) + '/' + value.substr(5, 2)
        case 'InscricaoEstadual':
          return value.substr(0, 8) + '-' + value.substr(8, 2)
        case 'CEP':
          return value.substr(0, 5) + '-' + value.substr(5, 3)
        case 'CPF':
          return value.substr(0, 3) + '.' + value.substr(3, 3) + '.' + value.substr(6, 3) + '-' + value.substr(9, 2)
        case 'Celular':
          value = ' + ' + value
          if (value.substr(0, 3) === ' + 55') {
            return ' + 55(' + value.substr(3, 2) + ') ' + value.substr(5, 5) + '-' + value.substr(10, 4)
          } else {
            value = value.replaceAll(' + ', '')
            return '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 4)
          }
        case 'Telefone':
          if (value.substr(0, 3) === ' + 55') {
            if (value.length > ' + ############'.length) {
              return '(' + value.substr(3, 2) + ') ' + value.substr(5, 5) + '-' + value.substr(10, 4)
            } else {
              return '(' + value.substr(3, 2) + ') ' + value.substr(5, 4) + '-' + value.substr(9, 4)
            }
          } else {
            if (value.length > '##########'.length) {
              return '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 4)
            } else {
              return '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(6, 4)
            }
          }
        default:
          return value
      }
    }
  }
}

export function applyDateFormat (data) {
  data = new Date(data)
  return (data.getDate()) + '/' + (data.getMonth() + 1) + '/' + data.getFullYear()
}
