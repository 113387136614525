
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Link',
  props: {
    label: String,
    classes: String
  }
})
