
import { defineComponent } from 'vue'
import { Input, PrimaryButton, Select, Card, Button, Modal } from '@design-system/ui'
import Toast from '../components/Toast.vue'
import { DatePicker } from 'v-calendar'
import axios from 'axios'
import { format, parseISO, addDays } from 'date-fns'

export default defineComponent({
  name: 'Auth',
  components: {
    Input,
    PrimaryButton,
    Button,
    DatePicker,
    Select,
    Card,
    Toast,
    Modal
  },
  data: () => {
    return {
      modal: false,
      hidden: true,
      mensagem: {
        titulo: '',
        descricao: '',
        package: null,
        dates: ''
      },
      registering: false,
      dateFilter: {},
      typeFilter: null,
      validations: {
        titulo: '',
        descricao: '',
        package: '',
        dates: ''
      },
      mensagens: [],
      loading: false,
      selectValues: [],
      selectedItem: '' as string
    }
  },
  created () {
    this.getPacotes()
    this.getData(true)
  },
  methods: {
    validate () {
      let errorCounter = 0
      Object.keys(this.mensagem).forEach((field) => {
        if (!this.mensagem[field] || this.mensagem[field] === '') {
          this.validations[field] = 'Esse campo é obrigatório'
          errorCounter++
        } else {
          this.validations[field] = ''
        }
      })
      return errorCounter === 0
    },

    getPacotes () {
      axios.get('https://clientes.tecnospeed.com.br/api/v1/consultapacotesparamensagem').then((response: any) => {
        this.selectValues = this.sort(response.data.resposta, 'descricao')
      })
    },

    width () {
      return window.innerWidth
    },

    back () {
      this.loading = false
      this.registering = false
      this.mensagem = {
        titulo: '',
        descricao: '',
        package: null,
        dates: ''
      }
      this.validations = {
        titulo: '',
        descricao: '',
        package: '',
        dates: ''
      }
      this.getData(true, this.typeFilter)
    },

    send () {
      this.loading = true
      if (this.validate()) {
        const data = {
          handle_pacote: this.mensagem.package,
          titulo: this.mensagem.titulo,
          descricao: this.mensagem.descricao.replaceAll('\n', '<br/>'),
          data_inicio: this.mensagem.dates.start.toISOString().split('T')[0],
          data_final: this.mensagem.dates.end.toISOString().split('T')[0]
        }
        axios.post('https://clientes.tecnospeed.com.br/api/v1/mensagem-produto', data).then((response: any) => {
          if (response.data.status === 'ERRO') {
            this.message = 'Houve um erro ao cadastrar a mensagem!'
            this.hidden = false
          }
          this.loading = false
          this.registering = false
          this.mensagem = {
            titulo: '',
            descricao: '',
            package: null,
            dates: ''
          }
          this.validations = {
            titulo: '',
            descricao: '',
            package: '',
            dates: ''
          }
          this.getData(true, this.typeFilter)
        }).catch(() => {
          this.message = 'Houve um erro ao cadastrar a mensagem!'
          this.hidden = false
          this.loading = false
        })
      }
    },

    async getData (firstTime: boolean, filter: number) {
      const today = new Date()
      const response = await axios.get('https://clientes.tecnospeed.com.br/api/v1/mensagem-produto', {
        params: {
          filtro_data: today.toISOString().split('T')[0],
          pacote_id: filter || 20
        }
      }).catch(() => {
        return { data: [] }
      })

      this.mensagens = response.data

      if (firstTime) {
        setTimeout(() => {
          var evt = new CustomEvent('list')
          window.dispatchEvent(evt)
        }, 1)
      }
    },

    changeType (value: any) {
      this.mensagem.package = value.handle_firebird
    },

    changeTypeFilter (value: any) {
      this.typeFilter = value.handle_firebird
      this.getData(false, this.typeFilter)
    },

    formatDate (date: string) {
      return format(addDays(parseISO(date), 1), 'dd/MM/yyyy')
    },

    sort (array, index) {
      return array.sort(function (first, second) {
        if (first[index] > second[index]) {
          return 1
        }
        if (first[index] < second[index]) {
          return -1
        }
        return 0
      })
    }
  }
})
