<template>
    <Card v-if="registering" orientation="flex-col" class="pt-8">
      <h1 class="px-8 inline-block text-3xl col-span-12 mb-5 font-bold text-gray-900 text-left w-full tracking-tight">Dados do Representante</h1>
      <p class="px-8 text-sm font-light w-full mb-5 text-gray-400">O Representante Legal é a pessoa física indicada no contrato ou estatuto social de uma empresa.</p>
        <form class="divide-y w-full px-8">
            <div class="grid grid-cols-12 mb-7 gap-5">
                <Input label="CPF" name="CPF" mask="###.###.###-##" v-model="representativeForm.CPF" :isIncorrect="!!formValidation.CPF" :incorrectMessage="formValidation.CPF" @change="validateCPF()" class="col-span-12 sm:col-span-6 lg:col-span-2"/>
                <Input label="Nome Completo" :autofocus="true" name="NomeCompleto" v-model="representativeForm.NomeCompleto" :isIncorrect="!!formValidation.NomeCompleto" class="col-span-12 sm:col-span-6 lg:col-span-5"/>
                <Input label="Nome Social" name="NomeSocial" :optional="true" v-model="representativeForm.NomeSocial" :isIncorrect="!!formValidation.NomeSocial" class="col-span-12 sm:col-span-6 lg:col-span-5"/>
                <Input label="RG" name="RG" v-model="representativeForm.RG" :isIncorrect="!!formValidation.RG" class="col-span-12 sm:col-span-6 lg:col-span-2"/>
                <Select event="select" id="UFRG" v-model="representativeForm.UFRG" :disabled="formFieldsLocked.UFRG" :searchable="true" display="nome" class="col-span-12 sm:col-span-6 lg:col-span-2 w-full UFRG" label="UF de Emissão" :options="() => UFs" :key="UFs" :selected="representativeForm.UFRG ? representativeForm.UFRG.nome : ''" :defaultMessage="representativeForm.UFRG ? representativeForm.UFRG.nome : 'Selecione uma UF'" :isIncorrect="!!formValidation.UFRG" :incorrectMessage="formValidation.UFRG"/>
                <Input label="Telefone" name="Telefone" :mask="['(##) ####-####','(##) #####-####']" type="phone" @change="validatePhone" v-model="representativeForm.Telefone" :isIncorrect="!!formValidation.Telefone" :incorrectMessage="formValidation.Telefone" class="col-span-12 sm:col-span-6 lg:col-span-4"/>
                <Input label="Celular" name="Celular" mask="(##) #####-####" type="phone" @change="validateCellphone" v-model="representativeForm.Celular" :isIncorrect="!!formValidation.Celular" :incorrectMessage="formValidation.Celular" class="col-span-12 sm:col-span-6 lg:col-span-4"/>
                <Input label="Nome da Mãe" name="NomeMae" v-model="representativeForm.NomeMae" :isIncorrect="!!formValidation.NomeMae" class="col-span-12 sm:col-span-6 lg:col-span-4"/>
                <Input label="E-mail Principal" name="Email" @change="validateEmail" v-model="representativeForm.Email" :isIncorrect="!!formValidation.Email" :incorrectMessage="formValidation.Email" class="col-span-12 sm:col-span-6 lg:col-span-5"/>
                <div class="grid col-span-12 sm:col-span-6 lg:col-span-3">
                    <label class="input-label leading-none h-5">Data de Nascimento</label>
                    <Datepicker v-model="representativeForm.DataNascimento" @focus="clearDate" @blur="validateDate" :typeable="true" :class="formValidation.DataNascimento ? 'border-red-500' : ''" inputFormat="dd/MM/y" :upperLimit="upperLimit" v-maska="'##/##/####'" class="col-span-12 h-12 w-full custom-input rounded-lg py-2 px-5 DataNascimento"/>
                    <span class="text-red-500 text-xs DataNascimento">{{formValidation.DataNascimento}}&nbsp;</span>
                </div>
            </div>

            <div class="grid grid-cols-12 mb-7 gap-5">
                <h1 class="inline-block text-3xl col-span-12 mt-5 font-bold text-gray-900 tracking-tight">Dados do Endereço</h1>
                <Input :loading="loadingAddressFields" label="CEP" name="CEP" :mask="'#####-###'" v-model="representativeForm.CEP" @change="validateCEP" :isIncorrect="!!formValidation.CEP" :incorrectMessage="formValidation.CEP" class="col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-2"/>
                <Input :loading="loadingAddressFields" label="Endereço" name="Endereco" :disabled="formFieldsLocked.Endereco" v-model="representativeForm.Endereco" :isIncorrect="!!formValidation.Endereco" class="col-span-12 md:col-span-4 lg:col-span-5"/>
                <Input :loading="loadingAddressFields" label="Número" name="Numero" v-model="representativeForm.Numero" :isIncorrect="!!formValidation.Numero" class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2"/>
                <Input :loading="loadingAddressFields" label="Complemento" name="Complemento" :optional="true" v-model="representativeForm.Complemento" :isIncorrect="!!formValidation.Complemento" class="col-span-12 sm:col-span-6 lg:col-span-3"/>
                <Input :loading="loadingAddressFields" label="Bairro" name="Bairro" :disabled="formFieldsLocked.Bairro" v-model="representativeForm.Bairro" :isIncorrect="!!formValidation.Bairro" class="col-span-12 sm:col-span-6 lg:col-span-4"/>
                <Select event="update:select" @update:select="getCity" id="UF" v-model="representativeForm.UF" :disabled="formFieldsLocked.UF" :searchable="true" display="nome" class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 w-full Cidade" label="UF" :key="UFs" :options="() => UFs" :defaultMessage="representativeForm.UF ? representativeForm.UF.nome : 'Selecione uma UF'" :isIncorrect="!!formValidation.UF" :incorrectMessage="formValidation.UF"/>
                <Select event="select" id="Cidade" v-model="representativeForm.Cidade" :selected="representativeForm.Cidade ? representativeForm.Cidade.nome : ''" :disabled="formFieldsLocked.Cidade" :searchable="true" display="nome" class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 w-full Cidade" label="Cidade" :key="cities" :options="() => cities" :defaultMessage="representativeForm.Cidade ? representativeForm.Cidade.nome : 'Selecione uma cidade'" :isIncorrect="!!formValidation.Cidade" :incorrectMessage="formValidation.Cidade"/>
                <Input event="select" id="Pais" v-model="representativeForm.Pais" disabled display="nome" :searchable="true" class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 w-full Pais" label="País" :options="() => [{nome: 'Brasil'}]" defaultMessage="Brasil" :isIncorrect="!!formValidation.Pais" :incorrectMessage="formValidation.Pais"/>
            </div>
        </form>
        <div class="flex mb-8 w-full">
            <Button @click="cancel" iconPosition="left" label="Cancelar" class="ml-auto h-12"/>
            <PrimaryButton @click="add" iconPosition="right" :loading="loading" :label="newRegister ? 'Adicionar' : 'Salvar'" class="ml-5 mr-8 next"/>
        </div>
    </Card>
    <Card orientation="flex-col" class="pt-8" v-else>
      <h1 class="px-8 inline-block text-3xl col-span-12 mb-9 font-bold text-gray-900 text-left w-full tracking-tight">Representantes Cadastrados</h1>
      <div class="pb-8 px-8 w-full">
        <div class="mb-9 grid grid-cols-12 gap-5">
            <div class="bg-gray-200 p-8 shadow-md rounded-lg mb-4 col-span-12 md:col-span-6 lg:col-span-4" v-for="representative in representatives" :key="representative.CPF">
                <div class="">
                    <p class="font-bold">{{representative.NomeCompleto}}</p>
                    <p>{{representative.CPF}}</p>
                    <p>{{representative.Telefone.replace('(', ' (').replace(')', ') ')}}</p>
                    <p>{{representative.Email}}</p>
                    <p>{{representative.Endereco}}</p>
                </div>
                <div class="mt-4 dropdown inline-block relative">
                    <button class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
                    <span class=""></span>
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                    </button>
                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                        <li v-if="representatives.length > 1" @click="remove(representative)" class=""><a class="min-w-max rounded-t bg-gray-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-red-500"><FontAwesomeIcon v-if="!loading" class="mr-3 self-center" :icon="trashIcon"/> Remover</a></li>
                        <li @click="edit(representative)" class="cursor-pointer"><a :class="representatives.length > 1 ? 'rounded-b' : 'rounded'" class="min-w-max bg-gray-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"><FontAwesomeIcon v-if="!loading" class="mr-3 self-center" :icon="editIcon"/> Editar</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="contents sm:flex">
            <Button @click="newRepresentative" iconPosition="left" label="Novo Representante" class="w-full h-12 sm:w-max mb-4 sm:mb-0"/>
            <Button @click="back" iconPosition="left" label="Voltar" class="sm:ml-auto h-12 w-full sm:w-min mb-4 sm:mb-0"/>
            <PrimaryButton @click="next" iconPosition="right" :loading="loading" label="Prosseguir" class="ml-none sm:ml-5 w-full sm:w-min next"/>
        </div>
      </div>
    </Card>
    <Modal :isOpen="modalOpen">
      <div class="px-8 py-5">
        <div class="title w-full text-center">Este CNPJ já consta em nossa base de dados!</div>
        <p v-if="!authored" class="center w-full my-8">Foi enviado um e-mail para ma****way@gm**l.com com um link para consentimento do uso dos dados.</p>
        <p v-else class="text-center w-full my-8">O uso dos dados foi autorizado com sucesso!</p>
        <div class="flex w-full">
          <PrimaryButton v-if="authored" @click="closeModal()" :btnClass="'next'" iconPosition="right" type="button" :isLoading="loading" label="Fechar" class="m-auto"/>
          <div v-else class="m-auto">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
</template>

<script>
import { Card, Input, PrimaryButton, Button, Select, Modal } from '@design-system/ui'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPen, faSave, faPlus, faChevronLeft, faChevronRight, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Datepicker from 'vue3-datepicker'
import axios from 'axios'
import { cep, cpf, email, phone } from 'validacao-docs'
import utils from '@/utils'
import { subYears, isAfter } from 'date-fns'
import { isEmail } from 'class-validator'

export default {
  name: 'RepresentativeData',
  props: ['data'],
  emits: ['back', 'next'],
  components: {
    Card,
    PrimaryButton,
    Button,
    Input,
    Datepicker,
    FontAwesomeIcon,
    Select,
    Modal
  },
  data () {
    return {
      id: 1,
      modalOpen: false,
      authored: false,
      upperLimit: subYears(Date.now(), 16),
      representatives: [],
      registering: true,
      newRegister: true,
      loading: false,
      spinner: faSpinner,
      editIcon: faPen,
      plusIcon: faPlus,
      saveIcon: faSave,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      trashIcon: faTrashAlt,
      representativeForm: {
        NomeCompleto: null,
        RG: null,
        Celular: null,
        Telefone: null,
        DataNascimento: null,
        NomeMae: null,
        Email: null,
        Bairro: null,
        Complemento: null,
        UF: null,
        Cidade: null,
        Endereco: null,
        Pais: {
          nome: 'Brasil'
        }
      },
      formValidation: {},
      formFieldsLocked: {},
      UFs: [],
      cities: [],
      date: null,
      loadingAddressFields: false,
      CPFSHOP: false,
      loadingFields: false,
      SHOPPerson: {}
    }
  },
  mounted () {
    this.getUF()
    this.getCity()
    if (this.data) {
      this.representatives = this.data
    }
    this.registering = this.representatives.length === 0
    this.formInitialize()
  },
  methods: {
    applyMask (index, value) {
      return utils.applyMask(index, value)
    },

    validateCPF () {
      this.formValidation.CPF = null

      if (this.representantes && this.representantes.filter((representante) => representante.CPF === this.representativeForm.CPF).length > 0) {
        this.formValidation.CPF = 'O CPF informado já consta na lista de representantes'
      }

      if (!cpf.isValid(this.representativeForm.CPF.replaceAll('.', '').replaceAll('-', ''))) {
        this.formValidation.CPF = 'Este CPF é inválido'
      }

      if (!cpf.isValidMask(this.representativeForm.CPF)) {
        this.formValidation.CPF = 'O formato do CPF é inválido'
      }
    },

    validateDate () {
      this.formValidation.DataNascimento = null
      if (isAfter(this.representativeForm.DataNascimento, subYears(Date.now(), 16))) this.formValidation.DataNascimento = 'O representante deve ser maior de 16 anos'
      if (!this.representativeForm.DataNascimento) this.formValidation.DataNascimento = 'A data informada não é válida'
    },

    clearDate () {
      this.representativeForm.DataNascimento = null
    },

    async validateCEP () {
      this.clearAddress()
      this.loadingAddressFields = true

      if (!!this.representativeForm.CEP && !cep.isValidMask(this.representativeForm.CEP)) {
        this.formValidation.CEP = 'O formato do CEP não é válido'
      } else {
        const address = await cep.getData(this.representativeForm.CEP.replace(/\D/g, ''))
        if (address) {
          this.representativeForm.Bairro = address.bairro ? address.bairro : null
          this.representativeForm.Complemento = address.complemento ? address.complemento : null
          this.formFieldsLocked.Complemento = address.complemento !== ''
          this.representativeForm.UF = address.uf ? this.UFs.filter((uf) => uf.sigla === address.uf)[0] : null
          await this.getCity()
          this.representativeForm.Cidade = address.localidade ? this.cities.filter((city) => city.nome === address.localidade)[0] : null
          this.representativeForm.Endereco = address.logradouro ? address.logradouro : null
        } else {
          this.representativeForm.Bairro = null
          this.representativeForm.Complemento = null
          this.representativeForm.UF = null
          this.representativeForm.Cidade = null
          this.representativeForm.Endereco = null
        }
      }
    },

    clearAddress () {
      this.formValidation.CEP = null
      this.formFieldsLocked = {}
    },

    validatePhone () {
      this.formValidation.Telefone = null
      if (this.representativeForm.Telefone && !phone.isValidMask('+55' + this.representativeForm.Telefone.replaceAll(' ', ''))) this.formValidation.Telefone = 'O formato do telefone não é válido'
    },

    validateCellphone () {
      this.formValidation.Celular = null
      if (this.representativeForm.Celular && !phone.isValidMask('+55' + this.representativeForm.Celular.replaceAll(' ', ''))) this.formValidation.Celular = 'O formato do celular não é válido'
    },

    validateEmail () {
      this.formValidation.Email = null
      if (!isEmail(this.representativeForm.Email)) this.formValidation.Email = 'O formato do e-mail não é válido'
    },

    closeModal () {
      this.modalOpen = false
      this.loadingFields = true
      this.representativeForm.NomeCompleto = this.SHOPPerson.Nome
      this.representativeForm.RG = this.SHOPPerson.RG
      this.representativeForm.Celular = this.SHOPPerson.Celular
      this.representativeForm.Telefone = this.SHOPPerson.Telefone
      this.representativeForm.DataNascimento = this.SHOPPerson.DataNascimento
      this.representativeForm.NomeMae = this.SHOPPerson.NomeMae
      this.representativeForm.Email = this.SHOPPerson.Email
      this.loadingFields = false
    },

    async getPerson () {
      if (this.representativeForm.CPF) {
        if (this.representativeForm.CPF.replace(/\D/g, '') === '09113326937') {
          this.SHOPPerson = {
            Nome: 'Matheus Amaral da Silva',
            RG: '10711123-9',
            Celular: '(44) 99770-3577',
            Telefone: '(44) 3234-3577',
            DataNascimento: new Date('1995-03-27'),
            NomeMae: 'Roseli Gall do Amaral da Silva',
            Email: 'matheusrockway@gmail.com'
          }
          this.modalOpen = true
          setTimeout(() => { this.authored = true }, 3000)
        }
      }
      this.loadingFields = false
    },

    remove (toBeRemoved) {
      this.representatives = this.representatives.filter(function (representante) { return representante !== toBeRemoved })
    },

    async edit (toBeUpdated) {
      this.registering = true
      this.newRegister = false
      this.representativeForm = await toBeUpdated
    },

    newRepresentative () {
      this.newRegister = true
      this.registering = true
    },

    add () {
      this.loading = true
      Object.keys(this.formValidation).forEach((index) => {
        this.formValidation[index] = null
      })

      if (this.representativeForm.Numero === '') {
        this.representativeForm.Numero = '0'
      }

      this.validateDate()
      this.validateCEP()
      this.validateCPF()
      this.validatePhone()
      this.validateCellphone()
      this.validateEmail()

      Object.keys(this.representativeForm).forEach((index) => {
        if ((!this.representativeForm[index] || this.representativeForm[index] === '') && index !== 'Complemento' && index !== 'NomeSocial') {
          this.formValidation[index] = 'Esse campo é obrigatório'
        }
      })

      const isNullUndefEmptyStr = Object.values(this.formValidation).every(value => {
        // 👇️ check for multiple conditions
        if (value === null || value === undefined || value === '') {
          return true
        }
        return false
      })

      if (isNullUndefEmptyStr) {
        if (this.newRegister) {
          this.representatives.push(this.representativeForm)
        } else {
          this.representatives = this.representatives.map(representative => {
            return representative.id === this.representativeForm.id ? this.representativeForm : representative
          })
        }
        this.loading = false
        this.formInitialize()
        this.formValidation = {}
        this.formFieldsLocked = {}
        this.registering = false
      }
    },

    next () {
      this.$emit('next', this.representatives)
    },

    back () {
      this.$emit('back', this.representatives)
    },

    cancel () {
      if (this.representatives.length === 0) {
        this.$emit('back', this.representatives)
      }
      this.formInitialize()
      this.formFieldsLocked = {}
      this.registering = false
    },

    async getUF () {
      let UFs = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').catch((response) => {
        this.$emit('registerLastRequest', response)
      })
      UFs = this.sort(UFs.data, 'sigla')
      this.UFs = UFs.map(UF => {
        UF.nome = UF.sigla
        return UF
      })
    },

    async getCity () {
      if (this.representativeForm.UF) {
        this.representativeForm.Cidade = null
        const cities = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + this.representativeForm.UF.sigla + '/municipios').catch((response) => {
          this.$emit('registerLastRequest', response)
        })
        this.cities = this.sort(cities.data, 'nome')
      }
    },

    async getAddressData () {
      this.loadingAddressFields = true
      cep.getData(this.representativeForm.CEP.replaceAll('-', '')).then(response => {
        if (response) {
          this.representativeForm.Endereco = response.logradouro
          this.formFieldsLocked.Endereco = !response.logradouro
          this.representativeForm.Bairro = response.bairro
          this.formFieldsLocked.Bairro = !response.bairro
          this.representativeForm.UF = response.uf
          this.formFieldsLocked.UF = !this.representativeForm.UF
          this.representativeForm.Cidade = response.localidade
          this.formFieldsLocked.Cidade = !this.representativeForm.Cidade
        }
        this.loadingAddressFields = false
      })
    },

    sort (array, index) {
      return array.sort(function (first, second) {
        if (first[index] > second[index]) {
          return 1
        }
        if (first[index] < second[index]) {
          return -1
        }
        return 0
      })
    },

    formInitialize () {
      this.representativeForm = {
        id: this.id += 1,
        NomeSocial: '',
        NomeMae: '',
        NomeCompleto: '',
        Email: '',
        Celular: '',
        Telefone: '',
        DataNascimento: null,
        CPF: '',
        RG: '',
        CEP: '',
        Numero: '',
        Bairro: '',
        Complemento: '',
        Endereco: '',
        UF: null,
        UFRG: null,
        Cidade: null,
        Pais: 'Brasil'
      }
    }
  }
}
</script>
