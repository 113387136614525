<style>

.tabs {
    border-radius: 5px;
    overflow: hidden;
}

.tab {
    align-items: center;
    width: 100%;
    color: white;
    overflow: hidden;
}
.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: var(--color-brand);
    background: linear-gradient(54deg, var(--color-brand), var(--color-brand-dark));
    font-weight: bold;
    cursor: pointer;
}
.tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: 0.35s;
}
.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    transition: 0.35s;
}
.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: var(--color-brand);
    cursor: pointer;
}
.tab-close:hover {
    background: var(--color-brand);
}

input:checked + .tab-label {
    background: var(--color-brand);
}
input:checked + .tab-label::after {
    transform: rotate(90deg);
}
input:checked ~ .tab-content {
    max-height: fit-content;
    padding: 1em;
}

input[type=checkbox] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
</style>
/* eslint-disable indent */
<template class="z-40 absolute">
    <div class="tabs custom-textarea">
        <div class="tab" v-if="data && data.empresa">
            <input type="checkbox" id="company" checked>
            <label class="tab-label" for="company" checked>Dados da Empresa</label>
            <div class="tab-content">
                <div class="grid grid cols-12 gap-5">
                    <div class="flex section-title col-span-12">
                        Dados Cadastrais
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CNPJ
                        </div>
                        <div class="data">
                            {{applyMask('CNPJ', data.empresa.CNPJ)}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Razão Social
                        </div>
                        <div class="data">
                            {{data.empresa.RazaoSocial}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Nome Fantasia
                        </div>
                        <div class="data">
                            {{data.empresa.NomeFantasia}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6" v-if="!data.empresa.Isento">
                        <div class="label">
                            Inscrição Estadual
                        </div>
                        <div class="data">
                            {{applyMask('IE',data.empresa.InscricaoEstadual)}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            CNAE
                        </div>
                        <div class="data">
                            {{applyMask('CNAE',data.empresa.CNAE)}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6" v-if="clientType == 'software-house'">
                        <div class="label">
                            Formato de Constituição
                        </div>
                        <div class="data">
                            {{data.empresa.ConstituicaoEmpresa.label}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Regime Tributário
                        </div>
                        <div class="data">
                            {{data.empresa.RegimeTributario.label}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Faturamento Mensal
                        </div>
                        <div class="data">
                            {{data.empresa.FaturamentoMensal}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Data de Abertura
                        </div>
                        <div class="data">
                            {{applyDateFormat(data.empresa.DataAbertura)}}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300"/>
                    <div class="section-title col-span-12">Dados Para Contato</div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            E-mail
                        </div>
                        <div class="data">
                            {{data.empresa.Email}}
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <div class="label">
                            Telefone
                        </div>
                        <div class="data">
                            {{applyMask('Telefone',data.empresa.Telefone.replaceAll('+55',''))}}
                        </div>
                    </div>
                    <div class="w-full col-span-12 border-t border border-grey-300"/>
                    <div class="section-title col-span-12">
                        Dados do Endereço
                    </div>
                    <div class="col-span-12">
                        {{ data.empresa.Endereco }}, {{ data.empresa.Numero }}. {{ data.empresa.Complemento ? '('+data.empresa.Complemento+')' : '' }} <br/>
                        {{ data.empresa.Bairro }}, {{ data.empresa.Cidade.nome }} - {{ data.empresa.UF.nome }} <br/>
                        {{ applyMask('CEP',data.empresa.CEP) }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="data && data.representantes">
            <div class="tab" :key="representante" v-for="representante in data.representantes">
                <input type="checkbox" :id="representante.CPF" checked>
                <label class="tab-label" :for="representante.CPF" checked>Dados do representante: {{representante.NomeCompleto}}</label>
                <div class="tab-content">
                    <div class="grid grid cols-12 gap-5">
                        <div class="flex section-title col-span-12">
                            Dados Cadastrais
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                CPF
                            </div>
                            <div class="data">
                                {{applyMask('CPF',representante.CPF)}}
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                RG
                            </div>
                            <div class="data">
                                {{applyMask('RG',representante.RG)}}
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                Nome Completo
                            </div>
                            <div class="data">
                                {{representante.NomeCompleto}}
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                Nome da Mãe
                            </div>
                            <div class="data">
                                {{representante.NomeMae}}
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                Data de Nascimento
                            </div>
                            <div class="data">
                                {{applyDateFormat(representante.DataNascimento)}}
                            </div>
                        </div>
                        <div class="w-full col-span-12 border-t border border-grey-300"/>
                        <div class="section-title col-span-12">Dados Para Contato</div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                E-mail
                            </div>
                            <div class="data">
                                {{representante.Email}}
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                Telefone
                            </div>
                            <div class="data">
                                {{applyMask('Telefone',representante.Telefone.replaceAll('+55',''))}}
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <div class="label">
                                Celular
                            </div>
                            <div class="data">
                                {{applyMask('Celular',representante.Celular)}}
                            </div>
                        </div>
                        <div class="w-full col-span-12 border-t border border-grey-300"/>
                        <div class="section-title col-span-12">
                            Dados do Endereço
                        </div>
                        <div class="col-span-12">
                            {{ representante.Endereco }}, {{ representante.Numero }}. {{ representante.Complemento ? '('+representante.Complemento+')' : '' }} <br/>
                            {{ representante.Bairro }}, {{ representante.Cidade.nome }} - {{ representante.UF.nome }} <br/>
                            {{ applyMask('CEP',representante.CEP) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ConfirmFormData',
  props: ['data', 'clientType'],
  data () {
    return {
      regimes: [
        { value: 'lucro_real', label: 'Lucro Real' },
        { value: 'lucro_presumido', label: 'Lucro Presumido' },
        { value: 'simples_nacional', label: 'Simples Nacional' },
        { value: 'mei', label: 'MEI' }
      ],
      formats: [
        { value: 'sociedade_anonima', label: 'Sociedade Anônima' },
        { value: 'sociedade_simples', label: 'Sociedade Simples' },
        { value: 'sociedade_empresaria_limitada', label: 'Sociedade Empresária Limitada' },
        { value: 'microempreendedor_individual', label: 'Microempreendedor Individual' },
        { value: 'empresa_individual_de_responsabilidade_limitada', label: 'Empresa Individual de Responsabilidade Limitada' },
        { value: 'empresa_individual', label: 'Empresa Individual' },
        { value: 'sociedade_limitada_unipessoal', label: 'Sociedade Limitada Unipessoal' }
      ]
    }
  },
  methods: {
    applyMask (name, value) {
      if (Array.isArray(name)) name = name[1]
      if (value) {
        value = value.replace(/[^a-z0-9]/gi, '')
        switch (name) {
          case 'CNPJ':
            return value.substr(0, 2) + '.' + value.substr(2, 3) + '.' + value.substr(5, 3) + '/' + value.substr(8, 4) + '-' + value.substr(12, 2)
          case 'CNAE':
            return value.substr(0, 4) + '-' + value.substr(4, 1) + '/' + value.substr(5, 2)
          case 'InscricaoEstadual':
            return value.substr(0, 8) + '-' + value.substr(8, 2)
          case 'CEP':
            return value.substr(0, 5) + '-' + value.substr(5, 3)
          case 'CPF':
            return value.substr(0, 3) + '.' + value.substr(3, 3) + '.' + value.substr(6, 3) + '-' + value.substr(9, 2)
          case 'Celular':
            value = ' + ' + value
            if (value.substr(0, 3) === ' + 55') {
              return ' + 55(' + value.substr(3, 2) + ') ' + value.substr(5, 5) + '-' + value.substr(10, 4)
            } else {
              value = value.replaceAll(' + ', '')
              return '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 4)
            }
          case 'Telefone':
            if (value.substr(0, 3) === ' + 55') {
              if (value.length > ' + ############'.length) {
                return '(' + value.substr(3, 2) + ') ' + value.substr(5, 5) + '-' + value.substr(10, 4)
              } else {
                return '(' + value.substr(3, 2) + ') ' + value.substr(5, 4) + '-' + value.substr(9, 4)
              }
            } else {
              if (value.length > '##########'.length) {
                return '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 4)
              } else {
                return '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(6, 4)
              }
            }
          default:
            return value
        }
      }
    },
    applyDateFormat (data) {
      data = new Date(data)
      return (data.getDate()) + '/' + (data.getMonth() + 1) + '/' + data.getFullYear()
    }
  }
}
</script>
