import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded-lg p-3 w-fill sm:w-1/3 flex justify-between", (_ctx.hidden ? 'disappear' : '')+' '+(_ctx.success ? 'success-toast' : 'toast')])
  }, [
    _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide')))
    }, [
      _createVNode(_component_FontAwesomeIcon, {
        icon: _ctx.close,
        class: "my-auto cursor-pointer"
      }, null, 8, ["icon"])
    ])
  ], 2))
}