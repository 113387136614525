
import { defineComponent } from 'vue'
import { Card, Form, Input, PrimaryButton, Password } from '@design-system/ui'
import Link from '../components/Link.vue'
import Toast from '../components/Toast.vue'

export default defineComponent({
  name: 'Auth',
  components: {
    Card,
    Form,
    Input,
    PrimaryButton,
    Toast,
    Link,
    Password
  },
  data: () => {
    return {
      state: 'authenticating',
      user: '',
      newUser: '',
      newUserMessage: '',
      userMessage: '',
      password: '',
      passwordMessage: '',
      email: '',
      emailMessage: '',
      cnpj: '',
      cnpjMessage: '',
      newPassword: '',
      repeatNewPassword: '',
      newPasswordMessage: '',
      repeatNewPasswordMessage: '',
      code: '',
      recoverStatus: 'identifying',
      validating: false,
      isNew: false,
      hidden: true,
      message: '',
      timeSeen: 20000,
      VUE_APP_TECNOPAY_ADMIN_API: 'https://sandbox.painel.tecnopay.me/api'
    }
  },
  methods: {
    authenticate () {
      if (!this.user || this.user === '') {
        this.userMessage = 'Informe um usuário'
      } else {
        this.userMessage = ''
      }
      if (!this.password || this.password === '') {
        this.passwordMessage = 'Informe uma senha'
      } else {
        this.passwordMessage = ''
      }
      if (this.userMessage === '' && this.passwordMessage === '') {
        if (this.user === 'portal' && this.password === 'portal') {
          this.state = 'loading'
          setTimeout(() => this.$router.push('painel'), 1000)
        } else {
          this.hidden = false
          this.message = 'Credenciais inválidas.'
        }
      }
    },

    recover (isNew : boolean) {
      this.isNew = isNew
      this.state = 'recovering'
      this.recoverStatus = 'identifying'
    },

    register () {
      this.state = 'registering'
      setTimeout(() => this.$router.push('cadastro'), 1000)
    },

    back () {
      this.state = 'authenticating'
    },

    hide () {
      this.hidden = true
    },

    identify (isNew: boolean) {
      if (isNew) {
        if (!this.email || this.email === '') {
          this.emailMessage = 'Informe um E-mail'
        } else {
          this.emailMessage = ''
        }
        if (!this.cnpj || this.cnpj === '') {
          this.cnpjMessage = 'Informe um CNPJ'
        } else {
          this.cnpjMessage = ''
        }
        if (this.cnpjMessage === '' && this.emailMessage === '') {
          // api.post('/accounts/first-access', {
          //   email: this.email,
          //   cnpj: this.cnpj.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '')
          // }).then(() => {
          // this.recoverStatus = 'validating'
          this.recoverStatus = 'redefining'
          // }).catch(() => {
          //   this.hidden = false
          //   this.message = 'Pessoa juridica não encontrada.'
          //   setTimeout(() => { this.hidden = true }, this.timeSeen)
          // })
        }
      } else {
        if (!this.user || this.user === '') {
          this.userMessage = 'Informe um usuário'
        } else {
          this.userMessage = ''
        }
        if (this.userMessage === '') {
          // api.post('/accounts/password', {
          //   username: this.user
          // }).then(() => {
          this.recoverStatus = 'validating'
          // }).catch(() => {
          //   this.hidden = false
          //   this.message = 'Pessoa juridica não encontrada.'
          //   setTimeout(() => { this.hidden = true }, this.timeSeen)
          // })
        }
      }
    },

    validate (value: any) {
      // api.post('/accounts/validate', {
      //   email: this.isNew ? this.email : this.user,
      //   codigo: value
      // }).then((response) => {
      //   if (response.data.valid) {
      //     this.recoverStatus = 'redefining'
      //   } else {
      //     this.hidden = false
      //     this.message = 'Código inválido.'
      //     setTimeout(() => { this.hidden = true }, this.timeSeen)
      //   }
      // }).catch(() => {
      //   this.hidden = false
      //   this.message = 'Código inválido.'
      //   setTimeout(() => { this.hidden = true }, this.timeSeen)
      // })
    },

    redefine () {
      if (!this.newPassword || this.newPassword === '') {
        this.newPasswordMessage = 'Informe uma senha'
      } else {
        this.newPasswordMessage = ''
      }
      if (!this.repeatNewPassword || this.repeatNewPassword === '') {
        this.repeatNewPasswordMessage = 'Informe uma senha'
      } else {
        if (this.newPassword !== this.repeatNewPassword) {
          this.repeatNewPasswordMessage = 'A senhas informadas devem ser iguais'
        } else {
          this.repeatNewPasswordMessage = ''
        }
      }
      if (this.newPasswordMessage === '' && this.repeatNewPasswordMessage === '') {
        if (this.isNew) {
          // api.post('/accounts/', {
          //   name: 'Tecnopay',
          //   username: this.newUser,
          //   password: this.newPassword,
          //   cnpj: this.cnpj.replaceAll('-', '').replaceAll('.', '').replaceAll('/', ''),
          //   system: 'tecnopay',
          //   type: 'responsavel',
          //   isApp: true
          // }).then(() => {
          //   this.state = 'recovery-loading'
          //   api.post('/accounts/login', {
          //     username: this.newUser,
          //     password: this.newPassword
          //   }).then((response) => {
          //     localStorage.setItem('token', response.data.accessToken)
          //     setTimeout(() => { this.$router.push('/estabelecimentos') }, 1500)
          //   }).catch(() => {
          //     this.state = 'authenticating'
          //   })
          // }).catch(() => {
          //   this.hidden = false
          //   this.message = 'Erro ao cadastrar as credenciais.'
          //   setTimeout(() => { this.hidden = true }, this.timeSeen)
          // })
        } else {
          // api.patch('/accounts/password', {
          //   username: this.user,
          //   password: this.newPassword
          // }).then(() => {
          //   this.state = 'recovery-loading'
          //   api.post('/accounts/login', {
          //     username: this.user,
          //     password: this.newPassword
          //   }).then((response) => {
          //     localStorage.setItem('token', response.data.accessToken)
          //     setTimeout(() => { this.$router.push('/estabelecimentos') }, 1500)
          //   }).catch(() => {
          //     this.state = 'authenticating'
          //   })
          // }).catch(() => {
          //   this.hidden = false
          //   this.message = 'Erro ao cadastrar as credenciais.'
          //   setTimeout(() => { this.hidden = true }, this.timeSeen)
          // })
        }
      }
    },

    clean () {
      this.user = ''
      this.newUser = ''
      this.newUserMessage = ''
      this.userMessage = ''
      this.password = ''
      this.passwordMessage = ''
      this.email = ''
      this.emailMessage = ''
      this.newPassword = ''
      this.repeatNewPassword = ''
      this.newPasswordMessage = ''
      this.repeatNewPasswordMessage = ''
    }
  }
})
