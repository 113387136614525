import api from '@/services/api'

export default class SessionService {
  public data (token: string) : any {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    const auth = JSON.parse(jsonPayload)
    return auth
  }

  public async softwareHouseData (token: string, cnpj: string) : Promise<any> {
    const softwareHouse = await api.get(`/software-houses/${cnpj}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return softwareHouse.data
  }

  public access () : boolean {
    if (localStorage.getItem('token')) {
      const base64Url = localStorage.getItem('token').split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      const auth = JSON.parse(jsonPayload)
      return !(auth.systems[0].type !== 'responsavel' && auth.systems[0].type !== 'representante')
    }
  }

  public cnpj () : any {
    if (localStorage.getItem('token')) {
      const base64Url = localStorage.getItem('token').split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      const auth = JSON.parse(jsonPayload)
      const cnpj = auth.systems[0].cnpj
      return cnpj
    }
  }
}
