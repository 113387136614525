<style>
    .custom-code-input {
        outline: none;
        border: solid 1px #e6e6e6;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding: 5px;
        height: 60px !important;
        max-height: 80px !important;
        font-size: 25px;
        font-weight: bolder;
        text-align: center;
    }

    .custom-code-input:focus {
        border: solid 1px #5854F1;
    }
</style>

<template>
    <div class="flex gap-2 items-center" v-if="!loading">
        <input maxlength="1" ref="one" @focus="clean('one')" class="custom-code-input rounded-lg p-3 w-16" @keyup="next('two')" v-model="one"/>
        <input maxlength="1" ref="two" @focus="clean('two')" class="custom-code-input rounded-lg p-3 w-16" @keyup="next('three')" v-model="two"/>
        <input maxlength="1" ref="three" @focus="clean('three')" class="custom-code-input rounded-lg p-3 w-16" @keyup="next('four')" v-model="three"/>
        <input maxlength="1" ref="four" @focus="clean('four')" class="custom-code-input rounded-lg p-3 w-16" @keyup="next('five')" v-model="four"/>
        <input maxlength="1" ref="five" @focus="clean('five')" class="custom-code-input rounded-lg p-3 w-16" @keyup="next('six')" v-model="five"/>
        <input maxlength="1" ref="six" @focus="clean('six')" class="custom-code-input rounded-lg p-3 w-16" @keyup="send" v-model="six"/>
    </div>
    <div v-else class="m-auto">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'CodeInput',
  props: ['loading'],
  data () {
    return {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null
    }
  },
  methods: {
    next (next) {
      this.$refs[next].value = null
      this.$refs[next].focus()
    },

    clean (index) {
      this.$refs[index].value = null
    },

    send () {
      this.$emit('changed', this.one + this.two + this.three + this.four + this.five + this.six)
      this.one = null
      this.two = null
      this.three = null
      this.four = null
      this.five = null
      this.six = null
      this.$refs.one.focus()
    }
  }
}
</script>
