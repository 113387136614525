<template>
    <Modal :id="value" v-if="validated" :hide="!validated">
        <template v-slot:label>
            <div class="custom-inverse-background hover:bg-dark transition text-white cursor-pointer w-100 my-5 shadow-xl rounded-lg p-3 text-center border"
                 @click="$emit('getCode')">
                <div class="grid">
                    <div class="truncate">Validar {{ formatedValue }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal>
            <div class="flex m-9 validate">
                <div class="gap-4 m-auto flex flex-col text-center">
                    <div class="mb-auto">
                        <p>Informe o código recebido para validar <strong>{{formatedValue}}</strong></p>
                    </div>
                    <Input :loading="loading" class="m-auto" :name="value" @changed="onChange" v-if="validated || validating"/>
                      <PrimaryButton @click="getCode()" :btnClass="'next'"  label="Reenviar código" class="code-link w-44 m-auto" v-if="validated || validating"/>
                </div>
            </div>
        </template>
    </Modal>
    <div class="items-center flex justify-between gap-5" v-else>
        <div class="truncate">{{ formatedValue }}</div>
        <div class="valid col-span-12 lg:col-span-6">
            <FontAwesomeIcon :icon="successIcon"/>
        </div>
    </div>
</template>

<script>
import Input from './CodeInput'
import Modal from '../Modal.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { PrimaryButton } from '@design-system/ui'

export default {
  name: 'Validator',
  props: ['value', 'formatedValue', 'validated', 'validating', 'type', 'loading'],
  emits: ['getCode', 'onChange'],
  components: {
    Input,
    Modal,
    FontAwesomeIcon,
    PrimaryButton
  },
  data () {
    return {
      successIcon: faCheckCircle
    }
  },
  methods: {
    onChange (code) {
      this.$emit('onChange', this.value, code)
    },
    getCode () {
      this.$emit('getCode')
    }
  }
}
</script>
