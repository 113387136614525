
import {
  Card,
  Input,
  PrimaryButton,
  Button,
  Select,
  Toggle,
  Modal
} from '@design-system/ui'
import {
  faPlus,
  faChevronLeft,
  faChevronRight,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
// import Multiselect from '@vueform/multiselect'
import Datepicker from 'vue3-datepicker'
import axios from 'axios'
import { cnpj, cep, cnae, email, phone } from 'validacao-docs'
import pt from 'date-fns/locale/pt'
import { VMoney } from 'v-money'
import Toast from '@/components/Toast.vue'
import { isAfter } from 'date-fns'
import api from '@/services/api'
import { isEmail } from 'class-validator'

export default {
  name: 'CompanyData',
  props: ['data'],
  emits: ['next', 'cancel'],
  components: {
    Card,
    PrimaryButton,
    Select,
    Button,
    Input,
    Toggle,
    Datepicker,
    Toast,
    Modal
  },
  directives: { money: VMoney },
  data () {
    return {
      locale: pt,
      cnpjLocked: false,
      mensagem: null,
      hidden: true,
      spinner: faSpinner,
      selectedValue: null,
      loading: false,
      plusIcon: faPlus,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      modalOpen: false,
      emailSend: '',
      authored: false,
      paymentDays: [10, 15, 20, 25],
      companyForm: {
        Vencimento: null,
        RazaoSocial: null,
        NomeFantasia: null,
        Email: null,
        Telefone: null,
        DataAbertura: null,
        CNAE: null,
        FaturamentoMensal: null,
        ConstituicaoEmpresa: null,
        CNPJ: null,
        CEP: null,
        InscricaoEstadual: null,
        Numero: null,
        Bairro: null,
        Complemento: null,
        Endereco: null,
        Pais: 'Brasil',
        UF: null,
        Cidade: null,
        Isento: true,
        RegimeTributario: null,
        TipoAtuacao: null,
        Clientes: null
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      shopCompany: {},
      formValidation: {},
      formFieldsLocked: {},
      UFs: [],
      cities: [],
      formats: [
        { value: 'sociedade_anonima', label: 'Sociedade Anônima' },
        { value: 'sociedade_simples', label: 'Sociedade Simples' },
        {
          value: 'sociedade_empresaria_limitada',
          label: 'Sociedade Empresária Limitada'
        },
        {
          value: 'microempreendedor_individual',
          label: 'Microempreendedor Individual'
        },
        {
          value: 'empresa_individual_de_responsabilidade_limitada',
          label: 'Empresa Individual de Responsabilidade Limitada'
        },
        {
          value: 'sociedade_limitada_unipessoal',
          label: 'Sociedade Limitada Unipessoal'
        }
      ],
      regimes: [
        { value: 'lucro_real', label: 'Lucro Real' },
        { value: 'lucro_presumido', label: 'Lucro Presumido' },
        { value: 'simples_nacional', label: 'Simples Nacional' },
        { value: 'mei', label: 'MEI' }
      ],
      atuacoes: [
        { label: 'Empresa de Software', value: 'empresa_software' },
        { label: 'Representante', value: 'integrador_representante_software' },
        { label: 'Empresa de Outro Ramo', value: 'empresa_outro_ramo' }
      ],
      date: null,
      loadingFields: false,
      loadingAddressFields: false,
      SHOPCompany: {},
      interval: null,
      getDataMessageHidden: true,
      getDataMessage: ''
    }
  },
  created () {
    this.getUF()
    this.getCity()
    if (Object.keys(this.data).length > 0) {
      this.companyForm = this.data
    }
  },
  methods: {
    hide () {
      this.hidden = true
    },

    selectVencimento (Vencimento) {
      this.companyForm.Vencimento = Vencimento.value
    },

    validateAmount (event) {
      if (event.target.value < 0) {
        event.target.value = 0
      }
    },

    validateDate () {
      this.formValidation.DataAbertura = null
      if (isAfter(this.companyForm.DataAbertura, Date.now())) {
        this.formValidation.DataAbertura =
          'A data não deve ser posterior ao dia de hoje'
      }
      if (!this.companyForm.DataAbertura) { this.formValidation.DataAbertura = 'A data informada não é válida' }
    },

    validateCNPJ () {
      this.formValidation.CNPJ = null

      if (
        this.companyForm.CNPJ &&
        !cnpj.isValid(this.companyForm.CNPJ.replace(/\D/g, ''))
      ) {
        this.formValidation.CNPJ = 'O CNPJ informado não é válido'
      }
      if (this.companyForm.CNPJ && !cnpj.isValidMask(this.companyForm.CNPJ)) {
        this.formValidation.CNPJ = 'O formato do CNPJ não é válido'
      }
    },

    async getCompany () {
      this.loadingFields = true
      this.authored = false

      if (this.companyForm.CNPJ && cnpj.isValidMask(this.companyForm.CNPJ)) {
        Object.keys(this.companyForm).forEach((index) => {
          if (index !== 'CNPJ' && index !== 'Isento' && index !== 'Pais') {
            this.companyForm[index] = null
          }
        })
      }
      if (this.companyForm.CNPJ) {
        const response: any = await api
          .get(
            `/clients/shop2/juridic-persons/${this.companyForm.CNPJ.replace(
              /\D/g,
              ''
            )}`
          )
          .catch((error) => {
            this.formValidation.CNPJ = error.response.data.message
            this.loadingFields = false
          })
        if (response.data.existsInShop2) {
          this.modalOpen = true
          this.emailSend = response.data.maskedEmailContact
          this.interval = setInterval(async () => {
            const permission: any = await api.get(
              `/clients/hash-masks/${this.companyForm.CNPJ.replace(/\D/g, '')}`
            )
            if (permission.data.isValidated) {
              this.authored = true
              this.SHOPCompany = permission.data.juridicPerson
              this.getDataMessage =
                'Dados obtidos através do seu consentimento do uso de nossa base de dados'
              this.getDataMessageHidden = false
              clearInterval(this.interval)
            }
            if (permission.response && permission.response.status === 400) {
              clearInterval(this.interval)
            }
          }, 5000)
        } else {
          this.getCNPJAData()
        }
      }
      this.loadingFields = false
    },

    closeModal () {
      this.modalOpen = false
      this.loadingFields = true
      clearInterval(this.interval)
      if (this.SHOPCompany) {
        this.fillCompanyData(this.SHOPCompany)
      }
      this.SHOPCompany = null
      this.loadingFields = false
    },

    closeModalOnly () {
      this.modalOpen = false
      this.loadingFields = true
      this.getCNPJAData()
      this.SHOPCompany = null
      this.loadingFields = false
    },

    async getCNPJAData () {
      const company: any = await api
        .get(`/clients/cnpja/${this.companyForm.CNPJ.replace(/\D/g, '')}`)
        .catch(() => {
          this.loadingFields = false
          Object.keys(this.companyForm).forEach((index) => {
            if (index !== 'CNPJ' && index !== 'Isento' && index !== 'Pais') {
              this.companyForm[index] = null
            }
          })
        })
      if (company.data) {
        this.fillCompanyData(company.data)
        this.getDataMessage =
          'Dados obtidos através dos dados abertos da Receita Federal'
        this.getDataMessageHidden = false
      }
    },

    async fillCompanyData (company) {
      this.companyForm.Pais = 'Brasil'
      this.companyForm.NomeFantasia = company.tradingName
      this.companyForm.RazaoSocial = company.legalName
      this.companyForm.Email = company.email
      this.companyForm.Telefone = company.phone
      this.companyForm.InscricaoEstadual = company.exemptFromTax
        ? company.stateRegistration
        : null
      this.companyForm.Isento =
        company && company.exemptFromTax ? company.exemptFromTax : false
      this.companyForm.DataAbertura = new Date(company.openingDate)
      this.companyForm.FaturamentoMensal = company.monthlyIncome.toString()
      this.companyForm.ConstituicaoEmpresa = this.formats.filter(
        (format) => format.value === company.constitution
      )[0]
      this.companyForm.RegimeTributario = this.regimes.filter(
        (regime) => regime.value === company.taxRegime
      )[0]
      this.companyForm.CNAE = company.cnae

      this.companyForm.CEP = company.address.zipCode
        ? company.address.zipCode
        : null
      this.companyForm.Numero = company.address.number
        ? company.address.number
        : null
      this.companyForm.Bairro = company.address.neighborhood
        ? company.address.neighborhood
        : null
      this.companyForm.UF = company.address.uf
        ? this.UFs.filter((uf) => uf.sigla === company.address.uf)[0]
        : null
      await this.getCity()
      this.companyForm.Cidade = company.address.city
        ? this.cities.filter(
          (city) =>
            city.nome.toLowerCase() === company.address.city.toLowerCase()
        )[0]
        : null
      this.companyForm.Endereco = company.address.street
        ? company.address.street
        : null
    },

    async validateCEP (onlyValidate = false) {
      this.clearAddress()
      this.loadingAddressFields = true

      if (!!this.companyForm.CEP && !cep.isValidMask(this.companyForm.CEP)) {
        this.formValidation.CEP = 'O formato do CEP não é válido'
      } else if (!onlyValidate) {
        const address: any = await cep.getData(
          this.companyForm.CEP.replace(/\D/g, '')
        )
        if (address) {
          this.companyForm.Bairro = address.bairro ? address.bairro : null
          this.formFieldsLocked.Bairro = address.bairro !== ''
          this.companyForm.Complemento = address.complemento
            ? address.complemento
            : null
          this.formFieldsLocked.Complemento = address.complemento !== ''
          this.companyForm.UF = address.uf
            ? this.UFs.filter((uf) => uf.sigla === address.uf)[0]
            : null
          this.formFieldsLocked.UF = address.uf !== ''
          await this.getCity()
          this.companyForm.Cidade = address.localidade
            ? this.cities.filter((city) => city.nome === address.localidade)[0]
            : null
          this.formFieldsLocked.Cidade = address.localidade !== ''
          this.companyForm.Endereco = address.logradouro
            ? address.logradouro
            : null
          this.formFieldsLocked.Endereco = address.logradouro !== ''
        } else {
          this.companyForm.Bairro = null
          this.companyForm.Complemento = null
          this.companyForm.UF = null
          this.companyForm.Cidade = null
          this.companyForm.Endereco = null
        }
      }

      this.loadingAddressFields = false
    },

    async validateCNAE () {
      this.formValidation.CNAE = null
      if (this.companyForm.CNAE && !cnae.isValidMask(this.companyForm.CNAE)) { this.formValidation.CNAE = 'O formato do CNAE informado não é válido' }
      if (this.companyForm.CNAE) {
        const isValid: any = await cnae.isValid(
          this.companyForm.CNAE.replace(/\D/g, '')
        )
        if (this.companyForm.CNAE && !isValid) { this.formValidation.CNAE = 'O CNAE informado não é válido' }
      }
    },

    validatePhone () {
      this.formValidation.Telefone = null
      if (
        this.companyForm.Telefone &&
        !phone.isValidMask(
          '+55' + this.companyForm.Telefone.replaceAll(' ', '')
        )
      ) { this.formValidation.Telefone = 'O formato do telefone não é válido' }
    },

    async validateEmail () {
      this.formValidation.Email = null
      if (!isEmail(this.companyForm.Email)) { this.formValidation.Email = 'O formato do e-mail não é válido' }
      if (this.companyForm.Email) {
        const response: any = await api.get(
          `/clients/user/${this.companyForm.Email}`
        )
        if (this.companyForm.Email && response.data.userAlreadyExists) { this.formValidation.Email = 'O e-mail informado já está sendo usado' }
      }
    },

    clearAddress () {
      this.formValidation.CEP = null
      this.formFieldsLocked = {}
    },

    next () {
      this.loading = true
      Object.keys(this.formValidation).forEach((index) => {
        this.formValidation[index] = null
      })

      if (this.companyForm.Numero === '') {
        this.companyForm.Numero = '0'
      }

      this.validateDate()
      this.validateCNPJ()
      this.validateCEP(true)
      this.validateCNAE()
      this.validatePhone()
      this.validateEmail()

      Object.keys(this.companyForm).forEach((index) => {
        if (
          (!this.companyForm[index] || this.companyForm[index] === '') &&
          index !== 'Complemento' &&
          index !== 'Isento' &&
          index !== 'Pais'
        ) {
          if (this.companyForm.Isento && index === 'InscricaoEstadual') {
          } else {
            this.formValidation[index] = 'Esse campo é obrigatório'
          }
        } else {
        }
      })

      const isNullUndefEmptyStr = Object.values(this.formValidation).every(
        (value) => {
          // 👇️ check for multiple conditions
          if (value === null || value === undefined || value === '') {
            return true
          }
          return false
        }
      )

      if (isNullUndefEmptyStr) {
        this.$emit('next', this.companyForm)
      }

      this.loading = false
    },

    back () {
      window.location.href = 'https://clientes.tecnospeed.com.br/'
    },

    async getUF () {
      const data = await axios.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
      )
      this.UFs = this.sort(data.data, 'sigla')
    },

    async getCity () {
      if (this.companyForm.UF) {
        this.companyForm.Cidade = null
        const data = await axios.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados/' +
            this.companyForm.UF.sigla +
            '/municipios'
        )
        this.cities = this.sort(data.data, 'nome')
      }
    },

    sort (array, index) {
      return array.sort(function (first, second) {
        if (first[index] > second[index]) {
          return 1
        }
        if (first[index] < second[index]) {
          return -1
        }
        return 0
      })
    }
  }
}
