<style>
    .modal {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: left;
        background: rgba(0,0,0,.4);
        transition: opacity .25s ease;
        z-index: 25;
    }

    .modal__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
    }

    .modal-state {
        display: none;
    }

    .modal-state:checked + .modal {
        opacity: 1;
        visibility: visible;
    }

    .modal-state:checked + .modal .modal__inner {
        top: 0;
    }

    .modal__inner {
        transition: top .25s ease;
        position: absolute;
        top: -20%;
        right: 0;
        bottom: 0;
        left: 0;
        width: 50%;
        margin: auto;
        overflow: auto;
        background: white;
        border-radius: 5px;
        max-height: 90%;
        height: fit-content;
    }

    .modal__close {
        position: absolute;
        right: 1em;
        top: 1em;
        width: 1.1em;
        height: 1.1em;
        cursor: pointer;
    }

    .modal__close:after,
    .modal__close:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 1.5em;
        background: #ccc;
        display: block;
        transform: rotate(45deg);
        left: 50%;
        margin: -3px 0 0 -1px;
        top: 0;
    }

    .modal__close:hover:after,
    .modal__close:hover:before {
        background: #aaa;
    }

    .modal__close:before {
        transform: rotate(-45deg);
    }

    @media screen and (max-width: 768px) {
        .modal__inner {
            width: 90%;
            box-sizing: border-box;
        }
    }
</style>

<template>
    <div class="self-center">
        <p>
            <label :for="id">
                <slot name="label">
                </slot>
            </label>
        </p>
    </div>

    <input class="modal-state" v-model="show" :id="id" :disabled="disabled" type="checkbox" />
    <div class="modal">
    <label class="modal__bg" :for="id"></label>
        <div class="modal__inner">
            <slot name="modal"></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['id', 'hide', 'disabled'],
  watch: {
    hide: function (value) {
      this.show = !value
    }
  },
  data () {
    return {
      show: false
    }
  },
  mounted () {
    this.show = this.hide
  }
}
</script>
