<template>
    <Card v-if="registering" orientation="flex-col" class="pt-8">
      <Toast label="Selecione um tipo de usuário" :hidden="hidden" @hide="hidden = !hidden" />
      <h1 class="px-8 inline-block text-3xl col-span-12 mb-5 font-bold text-gray-900 text-left w-full tracking-tight">Dados do Usuário</h1>
        <form class="divide-y w-full px-8">
            <div class="grid grid-cols-12 gap-5">
                <Input label="CPF" name="CPF" mask="###.###.###-##" v-model="technicalForm.CPF" :isIncorrect="!!formValidation.CPF" :incorrectMessage="formValidation.CPF" @change="validateCPF()" class="col-span-12 sm:col-span-6 lg:col-span-2" :autofocus="true"/>
                <Input label="Nome Completo" name="NomeCompleto" v-model="technicalForm.NomeCompleto" :isIncorrect="!!formValidation.NomeCompleto" class="col-span-12 sm:col-span-6 lg:col-span-4"/>
                <Input label="Celular" name="Celular" mask="(##) #####-####" type="phone" @change="validateCellphone" v-model="technicalForm.Celular" :isIncorrect="!!formValidation.Celular" :incorrectMessage="formValidation.Celular" class="col-span-12 sm:col-span-6 lg:col-span-2"/>
                <Input label="E-mail Principal" name="Email" @change="validateEmail" v-model="technicalForm.Email" :isIncorrect="!!formValidation.Email" :incorrectMessage="formValidation.Email" class="col-span-12 sm:col-span-6 lg:col-span-4"/>
            </div>
        </form>
        <h1 class="px-8 inline-block text-3xl col-span-12 mb-5 font-bold text-gray-900 text-left w-full tracking-tight">Selecione o(s) tipo(s) do usuário</h1>
        <div class="grid md:flex w-full gap-5 px-8 mb-8">
          <Card class="pt-8 px-6 w-12/12 md:w-6/12 lg:w-4/12 justify-start text-left" @click="assingType('responsavel')"
            :class="technicalForm.types && technicalForm.types.filter((type) => type === 'responsavel').length > 0 ? 'border-brand' : ''"
            title="Responsável pela Área Restrita"
            :hover="true"
            orientation="flex-col">
            <div class="py-8 pt-3 gap-5 text-center">
              <div class="block mb-10">
                Este usuário vai ser responsável por gerenciar dados da empresa e dos usuários cadastrados.
              </div>
            </div>
          </Card>
          <Card class="pt-8 px-6 w-12/12 md:w-6/12 lg:w-4/12 justify-start" @click="assingType('tecnico')"
            :class="technicalForm.types && technicalForm.types.filter((type) => type === 'tecnico').length > 0 ? 'border-brand' : ''"
            title="Responsável Técnico pelo Uso das Soluções"
            :hover="true"
            orientation="flex-col">
            <div class="py-8 pt-3">
              <div class="block md:flex gap-5 mb-5 text-center">
                Este usuário vai ser responsável por realizar  parametrização, homologação e será o contato principal com o nosso Suporte Técnico.
              </div>
            </div>
          </Card>
          <Card class="pt-8 px-6 w-12/12 md:w-6/12 lg:w-4/12 justify-start" @click="assingType('financeiro')"
            :class="technicalForm.types && technicalForm.types.filter((type) => type === 'financeiro').length > 0 ? 'border-brand' : ''"
            title="Responsável Financeiro"
            :hover="true"
            orientation="flex-col">
            <div class="py-8 pt-3">
              <div class="block md:flex gap-5 mb-5 text-center">
                Este usuário vai ser responsável para visualizar as informações financeiras e será o contato principal para os assuntos relacionados à cobrança.
              </div>
            </div>
          </Card>
        </div>
        <div class="flex mb-8 w-full">
            <Button @click="cancel" iconPosition="left" label="Cancelar" class="ml-auto h-12"/>
            <PrimaryButton @click="add" iconPosition="right" :loading="loading" :label="newRegister ? 'Adicionar' : 'Salvar'" class="ml-5 mr-8 next"/>
        </div>
    </Card>
    <Card orientation="flex-col" class="pt-8" v-else>
      <h1 class="px-8 inline-block text-3xl col-span-12 mb-9 font-bold text-gray-900 text-left w-full tracking-tight">Usuários Responsáveis</h1>
      <div class="pb-8 px-8 w-full">
        <div class="mb-9 grid grid-cols-12 gap-5">
            <div class="col-span-12" v-if="technicals.length === 0">Não existem usuários responsáveis cadastrados ainda.</div>
            <div class="col-span-12" v-if="technicals.filter((technical) => technical.type === 'responsavel').length === 0 && technicals.filter((technical) => technical.type === 'financeiro').length === 0 && technicals.filter((technical) => technical.type === 'tecnico').length === 0">Devem ser cadastrados ao menos um usuário de cada tipo (Responsável pelas soluções, responsável pela area restrita e financeiro).</div>
            <div class="bg-gray-200 p-8 shadow-md rounded-lg mb-4 col-span-12 md:col-span-6 lg:col-span-4" v-for="technical in technicals" :key="technical.CPF">
                <div class="">
                    <p class="font-bold">{{technical.NomeCompleto}}</p>
                    <p>{{technical.CPF}}</p>
                    <p>{{technical.Celular.replace('(', ' (').replace(')', ') ')}}</p>
                    <p>{{technical.Email}}</p>
                    <p v-for="typeItem in technical.types" :key="typeItem"> {{types[typeItem]}} </p>
                </div>
                <div class="mt-4 dropdown inline-block relative">
                    <button class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
                    <span class=""></span>
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                    </button>
                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                        <li v-if="technicals.length > 1" @click="remove(technical)" class=""><a class="min-w-max rounded-t bg-gray-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-red-500"><FontAwesomeIcon v-if="!loading" class="mr-3 self-center" :icon="trashIcon"/> Remover</a></li>
                        <li @click="edit(technical)" class="cursor-pointer"><a :class="technicals.length > 1 ? 'rounded-b' : 'rounded'" class="min-w-max bg-gray-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"><FontAwesomeIcon v-if="!loading" class="mr-3 self-center" :icon="editIcon"/> Editar</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="contents sm:flex">
            <Button @click="newTechnical" iconPosition="left" label="Novo Usuário" class="w-full h-12 sm:w-max mb-4 sm:mb-0"/>
            <Button @click="back" iconPosition="left" label="Voltar" class="sm:ml-auto h-12 w-full sm:w-min mb-4 sm:mb-0"/>
            <PrimaryButton @click="next" :disabled="!nextable()" iconPosition="right" :loading="loading" label="Prosseguir" class="ml-none sm:ml-5 w-full sm:w-min next"/>
        </div>
      </div>
    </Card>
</template>

<script>
import { Card, Input, PrimaryButton, Button, Toast } from '@design-system/ui'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPen, faSave, faPlus, faChevronLeft, faChevronRight, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { cpf, email, phone } from 'validacao-docs'
import utils from '@/utils'
import { isEmail } from 'class-validator'

export default {
  name: 'TechnicalData',
  props: ['data'],
  emits: ['back', 'next'],
  components: {
    Card,
    PrimaryButton,
    Button,
    Input,
    FontAwesomeIcon,
    Toast
  },
  data () {
    return {
      id: 1,
      technicals: [],
      registering: false,
      newRegister: true,
      loading: false,
      spinner: faSpinner,
      editIcon: faPen,
      plusIcon: faPlus,
      saveIcon: faSave,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      trashIcon: faTrashAlt,
      technicalForm: {
        NomeCompleto: null,
        Celular: null,
        Email: null,
        types: []
      },
      formValidation: {},
      formFieldsLocked: {},
      CPFSHOP: false,
      loadingFields: false,
      hidden: true,
      types: {
        financeiro: 'Financeiro',
        responsavel: 'Responsável pela area restrita',
        tecnico: 'Técnico do uso das soluções'
      }
    }
  },
  mounted () {
    if (this.data) {
      this.technicals = this.data
    }
    this.formInitialize()
  },
  methods: {
    applyMask (index, value) {
      return utils.applyMask(index, value)
    },

    nextable () {
      return this.technicals.filter((technical) => technical.types.filter((type) => type === 'responsavel').length > 0).length > 0 &&
      this.technicals.filter((technical) => technical.types.filter((type) => type === 'financeiro').length > 0).length > 0 &&
      this.technicals.filter((technical) => technical.types.filter((type) => type === 'tecnico').length > 0).length > 0
    },

    validateCPF () {
      this.formValidation.CPF = null

      if (this.representantes && this.representantes.filter((representante) => representante.CPF === this.technicalForm.CPF).length > 0) {
        this.formValidation.CPF = 'O CPF informado já consta na lista de representantes'
      }

      if (!cpf.isValid(this.technicalForm.CPF.replaceAll('.', '').replaceAll('-', ''))) {
        this.formValidation.CPF = 'Este CPF é inválido'
      }

      if (!cpf.isValidMask(this.technicalForm.CPF)) {
        this.formValidation.CPF = 'O formato do CPF é inválido'
      }
    },

    validateCellphone () {
      this.formValidation.Celular = null
      if (this.technicalForm.Celular && !phone.isValidMask('+55' + this.technicalForm.Celular.replaceAll(' ', ''))) this.formValidation.Celular = 'O formato do celular não é válido'
    },

    validateEmail () {
      this.formValidation.Email = null
      if (!isEmail(this.technicalForm.Email)) this.formValidation.Email = 'O formato do e-mail não é válido'
    },

    assingType (selectedType) {
      if (!this.technicalForm.types) {
        this.technicalForm.types = []
      }
      if (this.technicalForm.types.filter((type) => type === selectedType).length === 0) {
        this.technicalForm.types.push(selectedType)
      } else {
        this.technicalForm.types = this.technicalForm.types.filter((type) => type !== selectedType)
      }
    },

    remove (toBeRemoved) {
      this.technicals = this.technicals.filter(function (representante) { return representante !== toBeRemoved })
    },

    async edit (toBeUpdated) {
      this.registering = true
      this.newRegister = false
      this.technicalForm = await toBeUpdated
    },

    newTechnical () {
      this.newRegister = true
      this.registering = true
    },

    add () {
      this.loading = true
      Object.keys(this.formValidation).forEach((index) => {
        this.formValidation[index] = null
      })

      this.validateCPF()
      this.validateCellphone()
      this.validateEmail()
      Object.keys(this.technicalForm).forEach((index) => {
        if ((!this.technicalForm[index] || this.technicalForm[index] === '' || (Array.isArray(this.technicalForm[index]) && this.technicalForm[index].length === 0)) && index !== 'type') {
          this.formValidation[index] = 'Esse campo é obrigatório'
        }
      })

      if (!this.technicalForm.types || this.technicalForm.types.length <= 0) {
        this.hidden = false
        this.formValidation.types = 'erro'
      } else {
        this.formValidation.types = null
      }

      const isNullUndefEmptyStr = Object.values(this.formValidation).every(value => {
        // 👇️ check for multiple conditions
        if (value === null || value === undefined || value === '') {
          return true
        }
        return false
      })
      if (isNullUndefEmptyStr) {
        if (this.newRegister) {
          this.technicals.push(this.technicalForm)
        } else {
          this.technicals = this.technicals.map(technical => {
            return technical.id === this.technicalForm.id ? this.technicalForm : technical
          })
        }
        this.loading = false
        this.formInitialize()
        this.formValidation = {}
        this.formFieldsLocked = {}
        this.registering = false
      }
    },

    next () {
      this.$emit('next', this.technicals)
    },

    back () {
      this.$emit('back', this.technicals)
    },

    cancel () {
      this.formInitialize()
      this.formFieldsLocked = {}
      this.registering = false
    },

    sort (array, index) {
      return array.sort(function (first, second) {
        if (first[index] > second[index]) {
          return 1
        }
        if (first[index] < second[index]) {
          return -1
        }
        return 0
      })
    },

    formInitialize () {
      this.technicalForm = {
        id: this.id += 1,
        NomeCompleto: '',
        Email: '',
        Celular: '',
        CPF: '',
        types: []
      }
    }
  }
}
</script>
