import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Auth from '../views/Auth.vue'
import NotFound from '../views/NotFound.vue'
import Messages from '../views/Messages.vue'
import Dashboard from '../views/Dashboard.vue'
import Download from '../views/Downloads.vue'
import Financial from '../views/Financial.vue'
import Licenses from '../views/Licenses.vue'
import Contracts from '../views/Contracts.vue'
import Users from '../views/Users.vue'
import Data from '../views/Data.vue'
import Create from '../components/Register/Create.vue'
import Permission from '../views/Permission.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/intranet',
    name: 'Intranet',
    component: Auth
  },
  {
    path: '/painel',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/financeiro',
    name: 'Financeiro',
    component: Financial
  },
  {
    path: '/licencas',
    name: 'Licencas',
    component: Licenses
  },
  {
    path: '/contratos',
    name: 'Contratos',
    component: Contracts
  },
  {
    path: '/usuario',
    name: 'Users',
    component: Users
  },
  {
    path: '/homologacao',
    name: 'Homologacao',
    component: Messages
  },
  {
    path: '/dados',
    name: 'Dados',
    component: Data
  },
  {
    path: '/mensagens',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: Create
  },
  {
    path: '/validateHash/:hash',
    name: 'Permission',
    component: Permission
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
