
import { defineComponent } from 'vue'
import { Sidebar, Header } from '@design-system/ui'
import SessionService from './util/SessionService'

export default defineComponent({
  name: 'App',
  components: {
    Sidebar,
    Header
  },
  data () {
    return {
      userData: {
        nomeFantasia: 'Teste'
      }
    }
  },
  async created () {
    if (!localStorage.getItem('token')) {
      // this.$router.push('/')
    }
    if (this.haveAccess()) {
      this.$store.commit('hideUsersMenu', false)
    } else {
      this.$store.commit('hideUsersMenu', true)
    }
  },
  async mounted () {
    const token = localStorage.getItem('token')
    if (token) {
      const session = new SessionService()
      const data = session.data(token)
      const softwareHouse = await session.softwareHouseData(token, data.systems[0].cnpj)
      this.userData.nomeFantasia = softwareHouse.pessoa.pessoaJuridica.nomeFantasia
      this.$store.commit('populateSoftwareHouse', softwareHouse)
      this.$store.commit('populateUser', data.systems[0])
    }
  },
  methods: {
    updateSidebar (index: number) {
      this.$store.commit('changeActiveMenu', index)
      if (!localStorage.getItem('token')) {
        // this.$router.push('/')
      }
    },

    updateCollapseSidebar (isCollapsed: boolean) {
      this.$store.commit('collapseSidebar', isCollapsed)
    },

    logout () {
      localStorage.removeItem('token')
      this.$router.push('/')
    },

    haveAccess () {
      const session = new SessionService()
      return session.access()
    }
  }
})
